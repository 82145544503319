.brk-slid {
  overflow: hidden;
  position: relative; }

.brk-slid-indents {
  padding: 15px; }

.slick-loading,
.init-slider {
  height: 400px;
  overflow: hidden;
  position: relative; }
  .slick-loading:before,
  .init-slider:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    z-index: 998; }

@keyframes loader {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }
  .slick-loading:after,
  .init-slider:after {
    content: '\f110';
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 1.875rem;
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 600;
    transform: translate(-50%, -50%);
    z-index: 999;
    animation: loader 1.5s infinite linear; }

.slick-list .slick-slide {
  outline: none; }

.default-slider {
  width: 100%; }
  .default-slider .slick-list {
    padding: 15px 0 25px; }
  .default-slider_mode .slick-list {
    padding: 5px 0 30px; }
  .default-slider_big .slick-list {
    padding: 15px 0 40px; }
  .default-slider_no-gutters .slick-list {
    margin: 0; }
  .default-slider_gutters-15 .slick-list {
    margin-left: -15px;
    margin-right: -15px; }
  .default-slider_gutters-15 .slick-slide {
    padding-left: 15px;
    padding-right: 15px; }

.staff-slider {
  position: relative;
  padding-bottom: 90px; }
  .staff-slider-container {
    padding-top: 10px;
    padding-bottom: 25px;
    position: relative; }
    .staff-slider-container .swiper-slide {
      width: 270px;
      padding-top: 15px;
      padding-bottom: 15px;
      position: relative; }
      .staff-slider-container .swiper-slide:before {
        content: '';
        position: absolute;
        top: 15px;
        right: 0;
        bottom: 15px;
        left: 0;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 16; }
        .brk-bordered-theme .staff-slider-container .swiper-slide:before {
          border-radius: var(--b-radius); }
      .staff-slider-container .swiper-slide.swiper-slide-active:before, .staff-slider-container .swiper-slide.swiper-slide-prev:before, .staff-slider-container .swiper-slide.swiper-slide-next:before {
        background-color: rgba(255, 255, 255, 0);
        z-index: -1; }
  .staff-slider_cornder-pagination .dots-base-staff-skin {
    transform: translate(-50%, 50%); }

@media screen and (min-width: 992px) {
  .brk-app-slider__img {
    max-width: none;
    width: 178%;
    margin-left: -32%; } }

.brk-app-slider__slide {
  overflow: hidden;
  padding-bottom: 30px; }

.rotation-slider {
  width: 100%;
  max-width: 710px; }
  .rotation-slider img {
    width: 100%;
    height: 100%; }
  .rotation-slider .slick-slide {
    position: relative;
    z-index: 7; }
    .rotation-slider .slick-slide .brk-slid {
      position: relative;
      transition: \transform 0.3s; }
      .rotation-slider .slick-slide .brk-slid:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.8);
        visibility: visible;
        transition: background-color 0.2s;
        z-index: 4; }
      .rotation-slider .slick-slide .brk-slid a {
        display: block;
        margin: 0 auto; }
    .rotation-slider .slick-slide.prev-slid-index .brk-slid {
      transform: translateX(20%) scale(0.85); }
      @media (max-width: 575px) {
        .rotation-slider .slick-slide.prev-slid-index .brk-slid {
          transform: translateX(0) scale(1); } }
    .rotation-slider .slick-slide.next-slid-index .brk-slid {
      transform: translateX(-20%) scale(0.85); }
      @media (max-width: 575px) {
        .rotation-slider .slick-slide.next-slid-index .brk-slid {
          transform: translateX(0) scale(1); } }
    .rotation-slider .slick-slide.slick-current, .rotation-slider .slick-slide.slick-center, .rotation-slider .slick-slide.slick-active {
      z-index: 8; }
      .rotation-slider .slick-slide.slick-current .brk-slid, .rotation-slider .slick-slide.slick-center .brk-slid, .rotation-slider .slick-slide.slick-active .brk-slid {
        transform: scale(1); }
        .rotation-slider .slick-slide.slick-current .brk-slid:before, .rotation-slider .slick-slide.slick-center .brk-slid:before, .rotation-slider .slick-slide.slick-active .brk-slid:before {
          background-color: rgba(255, 255, 255, 0);
          visibility: hidden; }
  .rotation-slider_full-width {
    max-width: none; }

.rotation-slider-min {
  max-width: 300px; }
  .rotation-slider-min img {
    width: 100%;
    height: auto; }

.slider-dark {
  width: 100%; }
  .slider-dark a {
    display: block; }
  .slider-dark img {
    width: 100%;
    height: auto;
    min-height: 100%; }

.slider-thumbnailed {
  position: relative; }
  .slider-thumbnailed img {
    width: 100%;
    height: auto;
    min-height: 100%; }
  .slider-thumbnailed-for {
    width: 100%; }
    .slider-thumbnailed-for a {
      display: block; }
  .slider-thumbnailed-nav {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    max-width: 430px;
    transform: translate(-50%, 50%);
    padding: 5px 7px 5px 5px;
    background-color: #fff; }
    @media (max-width: 575px) {
      .slider-thumbnailed-nav {
        left: auto;
        transform: none;
        position: static;
        max-width: 100%; } }
    .slider-thumbnailed-nav .brk-slid {
      padding: 5px;
      cursor: pointer; }
      .slider-thumbnailed-nav .brk-slid:after {
        content: '';
        display: block;
        padding-top: 100%; }
      .slider-thumbnailed-nav .brk-slid__overlay, .slider-thumbnailed-nav .brk-slid__icon {
        position: absolute;
        transition-duration: .4s; }
      .slider-thumbnailed-nav .brk-slid__overlay {
        transition-property: opacity; }
      .slider-thumbnailed-nav .brk-slid__icon {
        transition-property: \transform; }
      .slider-thumbnailed-nav .brk-slid__img {
        position: absolute;
        left: 5px;
        top: 5px;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        min-height: 0;
        object-fit: cover;
        object-position: center; }
      .slider-thumbnailed-nav .brk-slid__overlay {
        top: 10px;
        right: 10px;
        bottom: 10px;
        left: 10px;
        opacity: 0; }
      .slider-thumbnailed-nav .brk-slid__icon {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0); }
      .slider-thumbnailed-nav .brk-slid:hover .brk-slid__overlay {
        opacity: 1; }
      .slider-thumbnailed-nav .brk-slid:hover .brk-slid__icon {
        transform: translate(-50%, -50%) scale(1); }
  .slider-thumbnailed_shop .slider-thumbnailed-for .slick-slide, .slider-thumbnailed_shop-sidebar .slider-thumbnailed-for .slick-slide {
    background-color: #f3f3f3;
    border-radius: 10px;
    overflow: hidden; }
    @media (max-width: 1229px) {
      .slider-thumbnailed_shop .slider-thumbnailed-for .slick-slide, .slider-thumbnailed_shop-sidebar .slider-thumbnailed-for .slick-slide {
        height: 560px; } }
    @media (max-width: 575px) {
      .slider-thumbnailed_shop .slider-thumbnailed-for .slick-slide, .slider-thumbnailed_shop-sidebar .slider-thumbnailed-for .slick-slide {
        height: 490px; } }
    .slider-thumbnailed_shop .slider-thumbnailed-for .slick-slide img, .slider-thumbnailed_shop-sidebar .slider-thumbnailed-for .slick-slide img {
      position: absolute;
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
      min-height: 1px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .slider-thumbnailed_shop .slider-thumbnailed-for .slick-slide {
    height: 670px; }
  .slider-thumbnailed_shop-sidebar .slider-thumbnailed-for .slick-slide {
    height: 480px; }

.tiled-slider .slick-track {
  padding-top: 40px;
  padding-bottom: 40px; }

.tiled-slider .slick-track:not(:hover) .slick-center {
  z-index: 5;
  transform: scale(1.3); }
  .tiled-slider .slick-track:not(:hover) .slick-center .image-popup-gradient:after,
  .tiled-slider .slick-track:not(:hover) .slick-center .image-popup-gradient:before {
    opacity: 1; }

.tiled-slider .slick-slide {
  position: relative;
  z-index: 1;
  transform: scale(1);
  transition: \transform .4s; }
  .tiled-slider .slick-slide:hover {
    z-index: 5;
    transform: scale(1.3); }
  .tiled-slider .slick-slide .brk-slid {
    position: relative;
    padding-bottom: 100%; }
    .tiled-slider .slick-slide .brk-slid a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
      .tiled-slider .slick-slide .brk-slid a img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        min-width: 100%;
        max-width: none;
        height: 100%; }

.brk-carousel_project .brk-carousel__item {
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  padding-bottom: 30px; }

.brk-carousel_project .brk-carousel__bg {
  padding-top: 90px;
  background-color: #fdfdfd;
  box-shadow: inset 0 -5px 20px rgba(0, 0, 0, 0.04); }

.brk-carousel_project .brk-carousel__img-container {
  position: relative;
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.14); }
  .brk-carousel_project .brk-carousel__img-container:after {
    content: '';
    display: block;
    padding-top: 54.7%; }

.brk-carousel_project .brk-carousel__img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover; }

.brk-carousel_project .brk-carousel__about {
  display: flex;
  padding: 33px 28px 35px 46px;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.14);
  position: relative; }
  @media screen and (max-width: 1229px) {
    .brk-carousel_project .brk-carousel__about {
      flex-wrap: wrap; } }
  .brk-carousel_project .brk-carousel__about .btn-gradient {
    color: #fff;
    text-transform: uppercase;
    display: flex;
    font-size: 14px !important;
    justify-content: center;
    align-items: center; }
    .brk-carousel_project .brk-carousel__about .btn-gradient .icon {
      font-size: 21px;
      position: relative;
      right: 0;
      transition: right .3s;
      margin-left: 10px;
      margin-right: -8px; }
    .brk-carousel_project .brk-carousel__about .btn-gradient:hover {
      color: #fff; }
      .brk-carousel_project .brk-carousel__about .btn-gradient:hover .icon {
        right: -5px; }
    @media screen and (max-width: 1229px) {
      .brk-carousel_project .brk-carousel__about .btn-gradient {
        width: 100%;
        margin-top: 30px;
        margin-left: 0;
        margin-right: 0; } }

.brk-carousel_project .brk-carousel__control {
  display: flex;
  align-items: baseline;
  min-width: 140px; }
  @media screen and (max-width: 1229px) {
    .brk-carousel_project .brk-carousel__control {
      width: 100%;
      justify-content: center;
      margin-bottom: 15px; } }

.brk-carousel_project .brk-carousel__btn-prev, .brk-carousel_project .brk-carousel__btn-next {
  color: #272727;
  font-size: 24px;
  z-index: 1;
  position: relative;
  cursor: pointer;
  transition: opacity .3s, \transform .3s; }
  .brk-carousel_project .brk-carousel__btn-prev:hover, .brk-carousel_project .brk-carousel__btn-next:hover {
    transform: scale(1.1); }
  .brk-carousel_project .brk-carousel__btn-prev-icon, .brk-carousel_project .brk-carousel__btn-next-icon {
    font-size: 40px; }
  .brk-carousel_project .brk-carousel__btn-prev_disabled, .brk-carousel_project .brk-carousel__btn-next_disabled {
    pointer-events: none;
    opacity: 0.3; }

.brk-carousel_project .brk-carousel__btn-prev {
  margin-right: 27px;
  top: -10px;
  left: 1px; }

.brk-carousel_project .brk-carousel__btn-next {
  margin-left: 30px;
  top: -10px;
  left: -2px; }

.brk-carousel_project .brk-carousel__current {
  color: #272727;
  font-size: 52px;
  line-height: 60px; }

.brk-carousel_project .brk-carousel__count {
  position: relative; }
  .brk-carousel_project .brk-carousel__count::before {
    content: '/';
    margin-left: 5px;
    padding-left: 0;
    padding-right: 3px; }

.brk-carousel_project .slick-slide:focus {
  outline: none; }

.triple-slider .slick-slide {
  padding-left: 15px;
  padding-right: 15px; }

@media screen and (min-width: 991px) {
  .triple-slider.arrows-classic .slick-prev {
    left: calc(33% - 6px); }
  .triple-slider.arrows-classic .slick-next {
    right: calc(33% - 6px); } }

.triple-slider.arrows-classic .slick-prev:hover,
.triple-slider.arrows-classic .slick-next:hover {
  background-color: var(--brand-primary); }
  .triple-slider.arrows-classic .slick-prev:hover::before,
  .triple-slider.arrows-classic .slick-next:hover::before {
    color: #fff; }

.brk-brand-slider .slick-slide {
  display: flex;
  align-items: center;
  justify-content: center; }

.brk-slider-shop {
  position: relative; }
  @media screen and (max-width: 500px) {
    .brk-slider-shop {
      margin-top: 70px; } }
  .brk-slider-shop .brk-slider__nav {
    position: absolute;
    right: 0;
    top: -70px; }
    .brk-slider-shop .brk-slider__nav:hover button:first-child,
    .brk-slider-shop .brk-slider__nav:hover button:last-child {
      box-shadow: 0 3px 10px rgba(213, 213, 213, 0.5);
      background-color: #ffffff; }
    .brk-slider-shop .brk-slider__nav button {
      position: relative;
      width: 38px;
      height: 38px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      margin: 0;
      box-shadow: 0 3px 10px rgba(213, 213, 213, 0.5);
      background-color: #ffffff;
      transition-property: box-shadow, background-color;
      transition-duration: .3s; }
      .brk-slider-shop .brk-slider__nav button i {
        color: #d3d3d3; }
      .brk-slider-shop .brk-slider__nav button:first-child {
        right: -10px; }
      .brk-slider-shop .brk-slider__nav button:last-child {
        z-index: 1;
        box-shadow: 0 5px 16px rgba(39, 117, 255, 0.5);
        background-color: var(--brand-primary); }
      .brk-slider-shop .brk-slider__nav button:hover:first-child, .brk-slider-shop .brk-slider__nav button:hover:last-child {
        box-shadow: 0 5px 16px rgba(39, 117, 255, 0.5);
        background-color: var(--brand-primary);
        z-index: 2; }
        .brk-slider-shop .brk-slider__nav button:hover:first-child i, .brk-slider-shop .brk-slider__nav button:hover:last-child i {
          color: #fff; }
  .brk-slider-shop .slick-slide {
    padding: 30px 15px; }
  .brk-slider-shop .slick-list {
    margin-left: -15px;
    margin-right: -15px; }

.brk-services-slider-1 {
  max-width: none; }
  .brk-services-slider-1 .slick-slide {
    padding: 0 15px; }
  .brk-services-slider-1 .slick-list {
    margin-left: -15px;
    margin-right: -15px;
    overflow: hidden; }
  .brk-services-slider-1 [class*="dots-wrap-"] {
    transform: translate(-50%, 95px) !important; }

.slick-slider > button {
  background-color: transparent; }

.dots-base-color .dots-wrap {
  color: var(--brand-primary); }

.slick-dots {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  padding: 0 10px;
  width: auto;
  min-width: 182px;
  max-width: 100%;
  height: 54px;
  border-radius: 27px;
  background-color: #fff;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.07);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9; }
  .slick-dots li {
    position: relative;
    float: left;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid transparent;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      cursor: pointer;
      color: transparent;
      font-size: 0;
      line-height: 0;
      transition-property: width, height;
      transition-duration: .2s;
      background-color: var(--brand-primary); }
    .slick-dots li:hover, .slick-dots li.slick-active {
      border-color: #dbdbdb; }
      .slick-dots li:hover button, .slick-dots li.slick-active button {
        width: 0;
        height: 0; }

.dots-base-skin {
  position: relative; }
  .dots-base-skin.base-skin-top-left .dots-wrap {
    bottom: auto;
    left: 0;
    top: 0;
    transform: translate(0, 0); }
    @media (max-width: 991px) {
      .dots-base-skin.base-skin-top-left .dots-wrap {
        left: 50%;
        transform: translateX(-50%); } }
  .dots-base-skin .dots-wrap {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    padding: 0 10px;
    width: auto;
    min-width: 182px;
    height: 54px;
    border-radius: 27px;
    background-color: #fff;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.07);
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 9; }
    @media (max-width: 575px) {
      .dots-base-skin .dots-wrap {
        width: calc(100% - 30px); } }
    .dots-base-skin .dots-wrap .l-prev,
    .dots-base-skin .dots-wrap .l-next {
      width: 15px;
      cursor: pointer;
      background-color: transparent; }
      .dots-base-skin .dots-wrap .l-prev i,
      .dots-base-skin .dots-wrap .l-next i {
        vertical-align: -2px;
        font-size: 22px; }
    .dots-base-skin .dots-wrap .l-prev {
      margin-left: 9px; }
    .dots-base-skin .dots-wrap .l-next {
      margin-right: 9px; }
    .dots-base-skin .dots-wrap .slick-dots {
      padding: 0 15px;
      position: static;
      transform: none;
      width: auto;
      min-width: 1px;
      height: auto;
      border-radius: 0;
      box-shadow: none;
      display: block; }
      .dots-base-skin .dots-wrap .slick-dots li {
        position: relative;
        float: left;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 1px solid transparent;
        margin: 0 5px;
        padding: 0;
        cursor: pointer; }
        .dots-base-skin .dots-wrap .slick-dots li button {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          border-radius: 50%;
          cursor: pointer;
          color: transparent;
          font-size: 0;
          line-height: 0;
          transition-property: width, height;
          transition-duration: .2s; }
        .dots-base-skin .dots-wrap .slick-dots li:hover, .dots-base-skin .dots-wrap .slick-dots li.slick-active {
          border-color: #a2a2a2; }
          .dots-base-skin .dots-wrap .slick-dots li:hover button, .dots-base-skin .dots-wrap .slick-dots li.slick-active button {
            width: 0;
            height: 0; }
    [dir='rtl'] .dots-base-skin .dots-wrap .l-prev {
      order: 3; }
    [dir='rtl'] .dots-base-skin .dots-wrap .l-next {
      order: 1; }
    [dir='rtl'] .dots-base-skin .dots-wrap .slick-dots {
      order: 2; }

.dots-landscape-skin {
  position: relative; }
  .dots-landscape-skin .dots-wrap {
    position: absolute;
    bottom: 46px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 10px;
    width: auto;
    min-width: 310px;
    height: 54px;
    border-radius: 27px;
    background-color: #fff;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.07);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9; }
    @media (max-width: 767px) {
      .dots-landscape-skin .dots-wrap {
        min-width: 280px;
        bottom: 0;
        transform: translate(-50%, 50%); } }
    .dots-landscape-skin .dots-wrap .l-prev,
    .dots-landscape-skin .dots-wrap .l-next {
      width: 15px;
      cursor: pointer; }
      .dots-landscape-skin .dots-wrap .l-prev i,
      .dots-landscape-skin .dots-wrap .l-next i {
        vertical-align: -2px;
        font-size: 22px; }
    .dots-landscape-skin .dots-wrap .l-prev {
      margin-left: 17px; }
    .dots-landscape-skin .dots-wrap .l-next {
      margin-right: 17px; }
    .dots-landscape-skin .dots-wrap .slick-dots {
      padding: 0 15px;
      position: static;
      transform: none;
      width: auto;
      min-width: 1px;
      height: auto;
      border-radius: 0;
      box-shadow: none;
      display: block; }
      .dots-landscape-skin .dots-wrap .slick-dots li {
        position: relative;
        float: left;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 1px solid transparent;
        margin: 0 5px;
        padding: 0;
        cursor: pointer; }
        .dots-landscape-skin .dots-wrap .slick-dots li button {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          border-radius: 50%;
          cursor: pointer;
          color: transparent;
          font-size: 0;
          line-height: 0;
          transition-property: width, height;
          transition-duration: .2s; }
        .dots-landscape-skin .dots-wrap .slick-dots li:hover, .dots-landscape-skin .dots-wrap .slick-dots li.slick-active {
          border-color: #a2a2a2; }
          .dots-landscape-skin .dots-wrap .slick-dots li:hover button, .dots-landscape-skin .dots-wrap .slick-dots li.slick-active button {
            width: 0;
            height: 0; }

.dots-rounded-skin {
  position: relative; }
  .dots-rounded-skin .slick-dots {
    position: absolute;
    bottom: -23px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 10px;
    width: auto;
    min-width: 182px;
    max-width: 100%;
    height: 10px;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9; }
    .dots-rounded-skin .slick-dots li {
      position: relative;
      float: left;
      width: 26px;
      height: 9px;
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.13);
      margin: 0 5px;
      padding: 0;
      cursor: pointer; }
      .dots-rounded-skin .slick-dots li button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 22px;
        height: 5px;
        border-radius: 3px;
        cursor: pointer;
        color: transparent;
        font-size: 0;
        line-height: 0;
        transition: opacity .2s;
        opacity: 0;
        background-image: linear-gradient(to right, rgba(var(--brand-primary-rgb), 0.92), rgba(var(--secondary-rgb), 0.92)); }
      .dots-rounded-skin .slick-dots li:hover button, .dots-rounded-skin .slick-dots li.slick-active button {
        opacity: 1; }
  .dots-rounded-skin_top-right .slick-dots {
    position: absolute;
    right: -15px;
    top: -60px;
    justify-content: flex-end;
    transform: none; }
    @media (max-width: 575px) {
      .dots-rounded-skin_top-right .slick-dots {
        right: auto;
        top: -25px;
        transform: translateX(-50%); } }

.brk-swiper-default-pagination {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  bottom: 30px; }
  .brk-swiper-default-pagination-bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    opacity: .2;
    transition: opacity .3s;
    margin: 0 5px;
    background-color: var(--brand-primary); }
    .brk-swiper-default-pagination-bullet:hover, .brk-swiper-default-pagination-bullet-active {
      opacity: 1; }

.arrows-classic .slick-arrow {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 50px;
  height: 50px;
  padding: 0;
  transform: translateY(-50%);
  cursor: pointer;
  color: transparent;
  border: 0;
  border-radius: 50%;
  outline: none;
  z-index: 9;
  opacity: .9;
  transition: opacity .3s;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.12);
  background-color: var(--white); }
  @media (max-width: 576px) {
    .arrows-classic .slick-arrow {
      width: 40px;
      height: 40px; } }
  .arrows-classic .slick-arrow:hover {
    outline: none;
    opacity: 1; }
  .arrows-classic .slick-arrow:before {
    content: '';
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    vertical-align: 3px;
    font-size: 22px;
    transform: translate(-50%, -50%);
    color: var(--brand-primary); }

.arrows-classic .slick-prev {
  left: 10px; }
  .arrows-classic .slick-prev:before {
    content: '\f104'; }

.arrows-classic .slick-next {
  right: 10px; }
  .arrows-classic .slick-next:before {
    content: '\f105'; }

.arrows-classic .slick-disabled {
  background-color: #E6E6E6 !important;
  cursor: default; }
  .arrows-classic .slick-disabled:before {
    color: #828282 !important; }

.arrows-classic_shop .slick-prev {
  left: -25px; }

.arrows-classic_shop .slick-next {
  right: -25px; }

.arrows-classic_shop .slick-prev,
.arrows-classic_shop .slick-next {
  top: calc(50% - 45px); }

.arrows-classic-ellipse .slick-arrow {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 70px;
  height: 46px;
  padding: 0;
  transform: translateY(-50%);
  cursor: pointer;
  color: transparent;
  border: 0;
  border-radius: 27px;
  outline: none;
  z-index: 9;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.12);
  background-color: var(--white); }
  @media (max-width: 576px) {
    .arrows-classic-ellipse .slick-arrow {
      width: 40px;
      height: 40px;
      border-radius: 50%; } }
  .arrows-classic-ellipse .slick-arrow:hover {
    outline: none; }
  .arrows-classic-ellipse .slick-arrow:before {
    content: '';
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    vertical-align: 3px;
    font-size: 22px;
    transform: translate(-50%, -50%);
    color: var(--brand-primary); }

.arrows-classic-ellipse .slick-prev {
  left: -35px; }
  @media (max-width: 576px) {
    .arrows-classic-ellipse .slick-prev {
      left: 10px; } }
  .arrows-classic-ellipse .slick-prev:before {
    content: '\f104'; }

.arrows-classic-ellipse .slick-next {
  right: -35px; }
  @media (max-width: 576px) {
    .arrows-classic-ellipse .slick-next {
      right: 10px; } }
  .arrows-classic-ellipse .slick-next:before {
    content: '\f105'; }

.arrows-classic-ellipse .slick-disabled {
  background-color: #E6E6E6 !important;
  cursor: default; }
  .arrows-classic-ellipse .slick-disabled:before {
    color: #828282 !important; }

.arrows-classic-ellipse-mini .slick-arrow {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 33.2%;
  display: block;
  width: 53px;
  height: 32px;
  padding: 0;
  transform: translateY(-50%);
  cursor: pointer;
  color: transparent;
  border: 0;
  border-radius: 27px;
  outline: none;
  z-index: 9;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.12);
  background-color: var(--white); }
  @media (max-width: 576px) {
    .arrows-classic-ellipse-mini .slick-arrow {
      width: 40px;
      height: 40px;
      border-radius: 50%; } }
  .arrows-classic-ellipse-mini .slick-arrow:hover {
    outline: none; }
  .arrows-classic-ellipse-mini .slick-arrow:before {
    content: '';
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    vertical-align: 3px;
    font-size: 19px;
    transform: translate(-50%, -50%);
    color: var(--brand-primary); }

.arrows-classic-ellipse-mini .slick-prev {
  left: -20px; }
  @media (max-width: 576px) {
    .arrows-classic-ellipse-mini .slick-prev {
      left: 5px; } }
  .arrows-classic-ellipse-mini .slick-prev:before {
    content: '\f104'; }

.arrows-classic-ellipse-mini .slick-next {
  right: -20px; }
  @media (max-width: 576px) {
    .arrows-classic-ellipse-mini .slick-next {
      right: 5px; } }
  .arrows-classic-ellipse-mini .slick-next:before {
    content: '\f105'; }

.arrows-classic-ellipse-mini .slick-disabled {
  background-color: #E6E6E6 !important;
  cursor: default; }
  .arrows-classic-ellipse-mini .slick-disabled:before {
    color: #828282 !important; }

.arrows-classic-dark .slick-arrow {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 57%;
  display: block;
  width: 46px;
  height: 46px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  border-radius: 50%;
  outline: none;
  z-index: 9;
  opacity: .9;
  transition: opacity .3s;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.12);
  background-color: var(--brand-primary); }
  @media (max-width: 576px) {
    .arrows-classic-dark .slick-arrow {
      width: 40px;
      height: 40px;
      top: 50%;
      transform: translateY(-50%); } }
  .arrows-classic-dark .slick-arrow:hover {
    outline: none;
    opacity: 1; }
  .arrows-classic-dark .slick-arrow:before {
    content: '';
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 600;
    position: absolute;
    top: 50%;
    vertical-align: 3px;
    font-size: 14px;
    transform: translate(-50%, -50%);
    color: var(--white); }

.arrows-classic-dark .slick-prev {
  left: 14px;
  transform: translate(-50%, -50%); }
  @media (max-width: 1240px) {
    .arrows-classic-dark .slick-prev {
      left: 10px;
      transform: translate(0, -50%); } }
  .arrows-classic-dark .slick-prev:before {
    content: '\f0d9';
    left: calc(50% - 2px); }

.arrows-classic-dark .slick-next {
  right: 14px;
  transform: translate(50%, -50%); }
  @media (max-width: 1240px) {
    .arrows-classic-dark .slick-next {
      right: 10px;
      transform: translate(0, -50%); } }
  .arrows-classic-dark .slick-next:before {
    content: '\f0da';
    left: calc(50% + 2px); }

.arrows-classic-dark .slick-disabled {
  background-color: #E6E6E6 !important;
  cursor: default; }
  .arrows-classic-dark .slick-disabled:before {
    color: #828282 !important; }

.arrows-classic-dark-square .slick-arrow {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 52px;
  height: 52px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  z-index: 9;
  opacity: .9;
  transition: opacity .3s;
  background-color: var(--brand-primary); }
  @media (max-width: 576px) {
    .arrows-classic-dark-square .slick-arrow {
      width: 40px;
      height: 40px; } }
  .arrows-classic-dark-square .slick-arrow:hover {
    outline: none;
    opacity: 1; }
  .arrows-classic-dark-square .slick-arrow:before {
    content: '';
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 600;
    position: absolute;
    top: 50%;
    vertical-align: 3px;
    font-size: 14px;
    transform: translate(-50%, -50%);
    color: var(--white); }

.arrows-classic-dark-square .slick-prev {
  left: 0;
  transform: translate(-100%, -50%); }
  @media (max-width: 1279px) {
    .arrows-classic-dark-square .slick-prev {
      left: 10px;
      transform: translate(0, -50%); } }
  .arrows-classic-dark-square .slick-prev:before {
    content: '\f104';
    left: calc(50% - 2px); }

.arrows-classic-dark-square .slick-next {
  right: 0;
  transform: translate(100%, -50%); }
  @media (max-width: 1279px) {
    .arrows-classic-dark-square .slick-next {
      right: 10px;
      transform: translate(0, -50%); } }
  .arrows-classic-dark-square .slick-next:before {
    content: '\f105';
    left: calc(50% + 2px); }

.arrows-classic-dark-square .slick-disabled {
  background-color: #E6E6E6 !important;
  cursor: default; }
  .arrows-classic-dark-square .slick-disabled:before {
    color: #828282 !important; }

.arrows-classic-dark-circle .slick-arrow {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 42.5%;
  display: block;
  width: 46px;
  height: 46px;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  z-index: 9;
  opacity: .9;
  transition: opacity .3s;
  background-color: var(--brand-primary); }
  @media (max-width: 576px) {
    .arrows-classic-dark-circle .slick-arrow {
      width: 40px;
      height: 40px; } }
  .arrows-classic-dark-circle .slick-arrow:hover {
    outline: none;
    opacity: 1; }
  .arrows-classic-dark-circle .slick-arrow:before {
    content: '';
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 600;
    position: absolute;
    top: 50%;
    vertical-align: 3px;
    font-size: 19px;
    transform: translate(-50%, -50%);
    color: var(--white); }

.arrows-classic-dark-circle .slick-prev {
  left: 0;
  transform: translate(-50%, -50%); }
  @media (max-width: 1240px) {
    .arrows-classic-dark-circle .slick-prev {
      left: 10px;
      transform: translate(0, -50%); } }
  .arrows-classic-dark-circle .slick-prev:before {
    content: '\f104';
    left: 50%; }

.arrows-classic-dark-circle .slick-next {
  right: 0;
  transform: translate(50%, -50%); }
  @media (max-width: 1240px) {
    .arrows-classic-dark-circle .slick-next {
      right: 10px;
      transform: translate(0, -50%); } }
  .arrows-classic-dark-circle .slick-next:before {
    content: '\f105';
    left: 50%; }

.arrows-classic-dark-circle .slick-disabled {
  background-color: #E6E6E6 !important;
  cursor: default; }
  .arrows-classic-dark-circle .slick-disabled:before {
    color: #828282 !important; }

.arrows-dark .slick-arrow {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  display: block;
  width: 70px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  z-index: 9;
  background-image: none; }
  .arrows-dark .slick-arrow:before {
    content: '';
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 600;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 45px;
    z-index: 10;
    opacity: .4;
    transition: opacity .3s; }
    @media (max-width: 575px) {
      .arrows-dark .slick-arrow:before {
        top: 50%;
        transform: translateY(-50%); } }
  .arrows-dark .slick-arrow:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity .3s;
    z-index: 9; }
  .arrows-dark .slick-arrow:hover {
    outline: none; }
    .arrows-dark .slick-arrow:hover:before {
      opacity: 1; }

.arrows-dark .slick-prev {
  left: 0; }
  .arrows-dark .slick-prev:before {
    content: '\f104';
    left: 30px; }
  .arrows-dark .slick-prev:after {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)); }
  .arrows-dark .slick-prev:hover:after {
    opacity: 1; }

.arrows-dark .slick-next {
  right: 0; }
  .arrows-dark .slick-next:before {
    content: '\f105';
    right: 30px; }
  .arrows-dark .slick-next:after {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)); }
  .arrows-dark .slick-next:hover:after {
    opacity: 1; }

.arrows-dark .slick-prev:before,
.arrows-dark .slick-next:before {
  color: var(--white); }

.arrows-modern .slick-arrow {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 30px;
  height: 30px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  z-index: 9;
  background-image: none; }
  @media (max-width: 575px) {
    .arrows-modern .slick-arrow {
      top: 50%;
      transform: translateY(-50%); } }
  .arrows-modern .slick-arrow:before {
    content: '';
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 45px;
    z-index: 10;
    opacity: .4;
    transition: opacity .3s; }
  .arrows-modern .slick-arrow:hover {
    outline: none; }
    .arrows-modern .slick-arrow:hover:before {
      opacity: 1; }

.arrows-modern .slick-prev {
  left: 23px; }
  .arrows-modern .slick-prev:before {
    content: '\f104'; }

.arrows-modern .slick-next {
  right: 23px; }
  .arrows-modern .slick-next:before {
    content: '\f105'; }

.arrows-modern .slick-prev:before,
.arrows-modern .slick-next:before {
  color: var(--white); }

.arrows-modern_primary .slick-prev:before,
.arrows-modern_primary .slick-next:before {
  color: var(--brand-primary); }

.image-popup-gradient {
  position: relative;
  display: inline-block;
  z-index: 2;
  overflow: hidden; }
  .image-popup-gradient:before {
    content: '\f067';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.3);
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 600;
    opacity: 0;
    font-size: 23px;
    transition: opacity .4s;
    z-index: 6; }
    @media (max-width: 575px) {
      .image-popup-gradient:before {
        width: 38px;
        height: 38px;
        font-size: 14px; } }
  .image-popup-gradient:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity .4s;
    z-index: 4;
    background-image: linear-gradient(to right, rgba(var(--brand-primary-rgb), 0.84), rgba(var(--secondary-rgb), 0.84)); }
  .image-popup-gradient:hover:before {
    opacity: 1; }
  .image-popup-gradient:hover:after {
    opacity: 1; }

.dots-base-staff-skin {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 10px;
  width: auto;
  min-width: 182px;
  height: 54px;
  border-radius: 27px;
  background-color: #fff;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.07);
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 9; }
  @media (max-width: 575px) {
    .dots-base-staff-skin {
      width: calc(100% - 30px); } }
  .dots-base-staff-skin .swiper-arrow {
    background-color: var(--white); }
    .dots-base-staff-skin .swiper-arrow i {
      color: var(--brand-primary); }
  .dots-base-staff-skin .button-prev,
  .dots-base-staff-skin .button-next {
    width: 15px;
    cursor: pointer; }
    .dots-base-staff-skin .button-prev:focus,
    .dots-base-staff-skin .button-next:focus {
      outline: none; }
    .dots-base-staff-skin .button-prev i,
    .dots-base-staff-skin .button-next i {
      vertical-align: -5px;
      font-size: 1.375rem; }
  .dots-base-staff-skin .button-prev {
    margin-left: 9px; }
  .dots-base-staff-skin .button-next {
    margin-right: 9px; }
  .dots-base-staff-skin .pagination {
    padding: 0 15px;
    display: block; }
    .dots-base-staff-skin .pagination li {
      position: relative;
      float: left;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 1px solid transparent;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;
      background-color: transparent;
      opacity: 1; }
      .dots-base-staff-skin .pagination li:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        cursor: pointer;
        transition-property: width, height;
        transition-duration: .2s;
        background-color: var(--brand-primary); }
      .dots-base-staff-skin .pagination li:hover, .dots-base-staff-skin .pagination li.swiper-pagination-bullet-active {
        border-color: #dbdbdb;
        background-color: transparent; }
        .dots-base-staff-skin .pagination li:hover:before, .dots-base-staff-skin .pagination li.swiper-pagination-bullet-active:before {
          width: 0;
          height: 0; }

.dots-dash-five-skin {
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 10px;
  width: auto;
  min-width: 240px;
  height: 46px;
  border-radius: 23px;
  background-color: var(--brand-primary);
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 9; }
  .dots-dash-five-skin .button-prev,
  .dots-dash-five-skin .button-next {
    width: 15px;
    line-height: 46px;
    cursor: pointer;
    color: #fff;
    display: flex;
    outline: none; }
    .dots-dash-five-skin .button-prev i,
    .dots-dash-five-skin .button-next i {
      font-size: 0.875rem; }
  .dots-dash-five-skin .pagination {
    padding: 0 15px;
    display: block; }
    .dots-dash-five-skin .pagination li {
      position: relative;
      float: left;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 1px solid #fff;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;
      background-color: transparent;
      opacity: 1; }
      .dots-dash-five-skin .pagination li:hover, .dots-dash-five-skin .pagination li.swiper-pagination-bullet-active {
        background-color: #fff; }

.brk-slider-team {
  bottom: -30px; }
  .brk-slider-team .slick-list {
    padding: 0; }

.brk-testimonials-dash-five .dots-dash-five-skin {
  bottom: -5px; }

.slick-slide > img {
  cursor: grab; }
  .slick-slide > img:active {
    cursor: grabbing; }

.brk-swiper-default-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: var(--brand-primary);
  box-shadow: 0 0 8px var(--brk-base-1);
  cursor: pointer;
  color: #fff;
  font-size: 1rem;
  outline: none; }
  .brk-swiper-default-nav i {
    vertical-align: 0;
    position: absolute;
    top: 50%; }
  .brk-swiper-default-nav-prev {
    left: -23px; }
    .brk-swiper-default-nav-prev i {
      left: calc(50% - 2px);
      transform: translate(-50%, -50%); }
  .brk-swiper-default-nav-next {
    right: -23px; }
    .brk-swiper-default-nav-next i {
      right: calc(50% - 2px);
      transform: translate(50%, -50%); }
  @media screen and (max-width: 575px) {
    .brk-swiper-default-nav-prev {
      left: 0; }
    .brk-swiper-default-nav-next {
      right: 0; } }

.brk-swiper-default-nav-light {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: var(--brand-primary);
  font-size: 2.25rem;
  line-height: 24px; }
  .brk-swiper-default-nav-light i {
    vertical-align: 0;
    position: absolute;
    top: calc(50% - 2px);
    left: 50%;
    transform: translate(-50%, -50%); }
  .brk-swiper-default-nav-light.brk-swiper-default-nav-prev {
    left: 14px; }
  .brk-swiper-default-nav-light.brk-swiper-default-nav-next {
    right: 14px; }

.brk-swiper-default-modern-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 30px;
  height: 30px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  outline: none;
  z-index: 9; }
  .brk-swiper-default-modern-arrow:before {
    content: '';
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 45px;
    z-index: 10;
    opacity: .4;
    transition: opacity .3s;
    color: #fff; }
  .brk-swiper-default-modern-arrow.brk-swiper-default-nav-prev {
    left: 23px; }
    .brk-swiper-default-modern-arrow.brk-swiper-default-nav-prev:before {
      content: '\f104'; }
  .brk-swiper-default-modern-arrow.brk-swiper-default-nav-next {
    right: 23px; }
    .brk-swiper-default-modern-arrow.brk-swiper-default-nav-next:before {
      content: '\f105'; }

.swiper-base-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 68px;
  height: 68px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 99;
  background-color: var(--brand-primary);
  box-shadow: 0 5px 12px var(--brk-base-1);
  outline: none; }
  @media (max-width: 575px) {
    .swiper-base-arrow {
      width: 50px;
      height: 50px; } }
  .swiper-base-arrow i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.3125rem;
    color: var(--white); }

.swiper-base-arrow-md {
  position: absolute;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 99;
  background-color: var(--brand-primary);
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.3); }
  .swiper-base-arrow-md i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.3125rem;
    color: var(--white); }

.swiper-base-arrow-vertical {
  position: absolute;
  width: 32px;
  height: 56px;
  cursor: pointer;
  z-index: 99; }
  .swiper-base-arrow-vertical i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.swiper-modern-arrow {
  position: absolute;
  top: 31px;
  right: 16px;
  width: 102px;
  height: 54px;
  border-radius: 27px;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.07);
  z-index: 19; }
  .swiper-modern-arrow i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    color: var(--brand-primary); }
  .swiper-modern-arrow .button-prev,
  .swiper-modern-arrow .button-next {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    cursor: pointer;
    outline: none;
    /*&:before,
		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 0;
			left: 0;
			transition: height .2s;
			z-index: -1;
			background-color: var(--brk-base-4);
		}
		&:before {
			top: 0;
		}
		&:after {
			bottom: 0;
		}*/ }
    .swiper-modern-arrow .button-prev:hover,
    .swiper-modern-arrow .button-next:hover {
      /*&:before,
			&:after {
				height: 50%;
			}*/ }
      .swiper-modern-arrow .button-prev:hover i,
      .swiper-modern-arrow .button-next:hover i {
        color: #222; }
  .swiper-modern-arrow .button-prev {
    left: 0; }
  .swiper-modern-arrow .button-next {
    right: 0; }

.slider-thumbnailed-full {
  position: relative; }
  .slider-thumbnailed-full-for {
    width: 100%; }
    .slider-thumbnailed-full-for .swiper-slide {
      width: 100%;
      height: 0;
      padding-bottom: 66.66666%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover; }
    .height-100vh .slider-thumbnailed-full-for .swiper-slide {
      width: 100%;
      height: 100vh;
      padding-bottom: 0; }
  .slider-thumbnailed-full-nav {
    position: absolute;
    bottom: 55px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 700px;
    z-index: 2; }
    @media (max-width: 991px) {
      .slider-thumbnailed-full-nav {
        max-width: 372px; } }
    @media (max-width: 767px) {
      .slider-thumbnailed-full-nav {
        bottom: 15px;
        max-width: none; } }
    @media (max-width: 575px) {
      .slider-thumbnailed-full-nav {
        position: static;
        transform: none;
        padding: 5px 0 0; }
        .height-100vh .slider-thumbnailed-full-nav {
          position: absolute;
          transform: translateX(-50%);
          padding: 0 15px; } }
    .slider-thumbnailed-full-nav .swiper-slide {
      height: 80px;
      padding: 3px;
      cursor: pointer;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover; }
      .slider-thumbnailed-full-nav .swiper-slide:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        transition: background-color .4s;
        z-index: 3; }
      .slider-thumbnailed-full-nav .swiper-slide:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: opacity .4s;
        opacity: 0;
        z-index: 4;
        border: 2px solid #fff; }
        @media (max-width: 575px) {
          .slider-thumbnailed-full-nav .swiper-slide:after {
            border-color: var(--brand-primary); }
            .height-100vh .slider-thumbnailed-full-nav .swiper-slide:after {
              border-color: #fff; } }
      .slider-thumbnailed-full-nav .swiper-slide:hover:before, .slider-thumbnailed-full-nav .swiper-slide.swiper-slide-thumb-active:before {
        background-color: rgba(0, 0, 0, 0); }
      .slider-thumbnailed-full-nav .swiper-slide:hover:after, .slider-thumbnailed-full-nav .swiper-slide.swiper-slide-thumb-active:after {
        opacity: 1; }
  .slider-thumbnailed-full-prev, .slider-thumbnailed-full-next {
    font-size: 0.875rem;
    line-height: 48px;
    text-transform: uppercase;
    font-weight: 600;
    position: absolute;
    top: 50%;
    display: block;
    width: 78px;
    height: 48px;
    cursor: pointer;
    color: #fff;
    text-align: center;
    z-index: 9;
    background-color: rgba(38, 38, 38, 0.6);
    transform-origin: center center;
    transform: translateY(-50%) rotate(-90deg);
    transition: background-color .3s;
    outline: none; }
    .slider-thumbnailed-full-prev:hover, .slider-thumbnailed-full-next:hover {
      background-color: #262626; }
  .slider-thumbnailed-full-prev {
    left: -15px; }
  .slider-thumbnailed-full-next {
    right: -15px; }

.dash-three-slider .swiper-pagination-bullet-active {
  background: var(--brand-primary); }

.brk-services-simple {
  position: relative; }
  .brk-services-simple .swiper-slide {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    transition: opacity .5s; }
    @media (min-width: 576px) {
      .brk-services-simple .swiper-slide {
        max-width: 356px;
        opacity: 0.1; } }
    @media (max-width: 575px) {
      .brk-services-simple .swiper-slide {
        padding-left: 15px;
        padding-right: 15px; } }
    .brk-services-simple .swiper-slide:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 3; }
    .brk-services-simple .swiper-slide-active {
      opacity: 1; }
      .brk-services-simple .swiper-slide-active:before {
        display: none; }
    @media (min-width: 992px) {
      .brk-services-simple .swiper-slide-prev, .brk-services-simple .swiper-slide-next {
        opacity: 1; }
        .brk-services-simple .swiper-slide-prev:before, .brk-services-simple .swiper-slide-next:before {
          display: none; } }
  .brk-services-simple__navigation {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    width: auto;
    max-width: 100%;
    min-width: 182px;
    height: 54px;
    border-radius: 27px;
    background-color: #fff;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.07);
    padding: 0 15px; }
    .brk-services-simple__navigation .services-simple-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 8px; }
      .brk-services-simple__navigation .services-simple-pagination-bullet {
        position: relative;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 1px solid transparent;
        margin: 5px;
        cursor: pointer;
        transition: border-color .3s; }
        .brk-services-simple__navigation .services-simple-pagination-bullet:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: var(--brand-primary);
          transition: background-color .3s; }
        .brk-services-simple__navigation .services-simple-pagination-bullet:hover, .brk-services-simple__navigation .services-simple-pagination-bullet-active {
          border-color: #a2a2a2; }
          .brk-services-simple__navigation .services-simple-pagination-bullet:hover:before, .brk-services-simple__navigation .services-simple-pagination-bullet-active:before {
            background-color: transparent; }
        .brk-services-simple__navigation .services-simple-pagination-bullet:focus {
          outline: none; }
    .brk-services-simple__navigation .services-simple-button-prev,
    .brk-services-simple__navigation .services-simple-button-next {
      line-height: 22px;
      color: var(--brand-primary);
      cursor: pointer;
      width: 24px;
      text-align: center; }
      .brk-services-simple__navigation .services-simple-button-prev:focus,
      .brk-services-simple__navigation .services-simple-button-next:focus {
        outline: none; }
      .brk-services-simple__navigation .services-simple-button-prev i,
      .brk-services-simple__navigation .services-simple-button-next i {
        vertical-align: -2px;
        font-size: 22px; }
